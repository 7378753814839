import React from "react";
import { ContentWrapper } from "./wrapper";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { TitleSection } from "./title";
import { ContentContainer } from "./container";
import { TagInfos } from "./tags";

export const ContentSection = ({ body, title, date, tags }) => {
  return (
    <ContentWrapper>
      <TitleSection title={title} date={date} />
      <ContentContainer>
        <MDXRenderer>{body}</MDXRenderer>
      </ContentContainer>
      <TagInfos tags={tags} />
    </ContentWrapper>
  );
};
