import React from "react";
import { SideInfoContainer } from "./container";
import { SText } from "./text";
import { Views } from "./views";
import { SideInfoWrapper } from "./wrapper";

export const SideInfo = ({ date }) => {
  return (
    <SideInfoWrapper>
      <SideInfoContainer>
        <Views />
        <SText>{date}</SText>
      </SideInfoContainer>
    </SideInfoWrapper>
  );
};
