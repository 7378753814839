import React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image"; // import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { useHashForInitialPosition } from "../hooks";
import SeoHelmet from "../components/seo";
import Layout from "../components/layout";
import { Blog } from "../components/blog";
import { DisplayAds } from "../components/display-ad";

const BlogPost = ({ data }) => {
  const {
    site: {
      siteMetadata: { siteUrlNoTrailingSlash },
    },
    mdx: {
      frontmatter: {
        title,
        description,
        short_description,
        date,
        hero_image,
        hero_image_alt: imageAlt,
        category,
        tags,
      },
      parent: { modifiedTime },
      body,
      slug,
    },
  } = data;
  // const image = getImage(hero_image); // == hero_image.childImageSharp.gatsbyImageData
  const mainImageSrc = getSrc(hero_image); // == hero_image.childImageSharp.gatsbyImageData.images.fallback.src;

  useHashForInitialPosition();

  const articleInfoProps = {
    published_time: date,
    modified_time: modifiedTime,
    category: category,
    tags: tags,
    mainImageAlt: imageAlt,
  };

  const blogProps = {
    title: title,
    siteUrlNoTrailingSlash: siteUrlNoTrailingSlash,
    date: date,
    tags: tags,
    body: body,
  };

  return (
    <>
      <SeoHelmet
        title={title}
        description={short_description || description}
        mainImageSrc={mainImageSrc}
        articleInfo={articleInfoProps}
        pathname={`/${slug}`}
      />
      <Layout addDefaultMainAd={false}>
        {/* <GatsbyImage image={image} alt={imageAlt} /> */}
        <div style={{ margin: "0 20px"}}>
          <DisplayAds dataAdSlot={"8392800842"} />
        </div>
        <Blog blogProps={blogProps} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        siteUrlNoTrailingSlash
      }
    }
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
        short_description
        date(formatString: "YYYY-MM-DD")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData(width: 1200, aspectRatio: 2)
          }
        }
        category
        tags
      }
      slug
      parent {
        ... on File {
          modifiedTime(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`;

export default BlogPost;
