import styled from "styled-components";

export const DiagonalBox = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;

  height: 12px;
  width: 12px;

  ${({ theme }) => theme.transitionStyles("none")};
`;
