import React from "react";
import { TitleWrapper } from "./wrapper";
import { BlogTitle } from "./title";
import { SideInfo } from "./info";

export const TitleSection = ({ title, date }) => {
  return (
    <TitleWrapper>
      <BlogTitle>{title}</BlogTitle>
      <SideInfo date={date} />
    </TitleWrapper>
  );
};
