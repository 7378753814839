import React from "react";
import { BLOG_POST_SECTION_CLASS } from "../../constants";
import { ContentSection } from "./content";
import { HeaderNavigator } from "./navigator";
import { BlogWrapper } from "./wrapper";
import { useLocation } from "@reach/router";
import { useAddReferenceOnCopy } from "../../hooks";

export const Blog = ({ blogProps }) => {
  const { title, siteUrlNoTrailingSlash, date, tags, body } = blogProps;

  const { pathname } = useLocation();
  const pageUrl = siteUrlNoTrailingSlash + pathname;

  useAddReferenceOnCopy(title, pageUrl);

  return (
    <BlogWrapper className={BLOG_POST_SECTION_CLASS}>
      <ContentSection title={title} date={date} tags={tags} body={body} />
      <HeaderNavigator />
    </BlogWrapper>
  );
};
