import React from "react";
import { TagIconSolid as TagIcon } from "../../../common";
import { TagContainer } from "./container";
import { Tag } from "./tag";
import { TagWrapper } from "./wrapper";

export const TagInfos = ({ tags }) => {
  return (
    <>
      <TagWrapper>
        <TagIcon size={25} />
        <TagContainer>
          {tags?.map((tag, idx) => (
            <Tag key={idx} tag={tag} />
          ))}
        </TagContainer>
      </TagWrapper>
      <hr />
    </>
  );
};
