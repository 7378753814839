import styled from "styled-components";

export const Nav = styled.nav`
  position: -webkit-sticky;
  position: sticky;
  top: ${({ theme }) => theme.heightSize.HEADER_HEIGHT_COVERAGE + 5}px;
  align-self: start;

  margin-top: 40px;
`;
