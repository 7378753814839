import React from "react";
import { useFetchAndIncrementView } from "../../../../../hooks";
import { SText } from "./text";

export const Views = () => {
  const [loading, data] = useFetchAndIncrementView();

  if (loading || data < 30) {
    return <></>;
  }
  return <SText>{data} views | </SText>;
};
