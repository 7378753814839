import styled from "styled-components";

export const BlogWrapper = styled.div`
  display: block;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    display: grid;
    gap: 5px;
    grid-template-columns: 7fr 3fr;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
`;
