import styled from "styled-components";

export const Button = styled.button`
  all: unset;
  ${({ theme }) => theme.baseStyles()};

  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 10px;

  display: flex;
  align-items: center;

  border-left: ${({ level, theme }) =>
    level > 0 ? `2px solid ${theme.colors.grayBorder}` : ``};

  cursor: pointer;

  color: ${({ theme }) => theme.colors.textColor};

  background-color: ${({ isCurrentPage, theme }) =>
    isCurrentPage
      ? theme.colors.minimumHighlight
      : theme.colors.backgroundColor};

  &:hover {
    background-color: ${({ theme }) => theme.colors.minimumHighlight};
    border-left: ${({ level, theme }) =>
      level > 0 ? `2px solid ${theme.colors.minimumHighlight}` : ``};

    & > div {
      background: ${({
        theme: {
          colors: { highlightColor, backgroundColor },
        },
      }) =>
        "linear-gradient(to right bottom, " +
        `${highlightColor} 50%, ` +
        `${backgroundColor} 50%);`};

      border-top: 1px solid ${({ theme }) => theme.colors.thickHighlightColor};
      border-left: 1px solid ${({ theme }) => theme.colors.thickHighlightColor};
    }
  }
`;
