import React from "react";
import { idToUrlHashFormat, scrollToHeaderElement } from "../../../../utils";
import { Button } from "./button";
import { DiagonalBox } from "./diagonal-box";
import { Li } from "./li";

export const HeaderNavigatorButton = ({
  element,
  rootHeaderLevel,
  isCurrentPositionList,
  idx,
}) => {
  const { tagName, id, innerText } = element;

  if (!["H1", "H2", "H3", "H4"].includes(tagName)) return;

  const handleOnClick = (headerId) => {
    window.location.hash = idToUrlHashFormat(headerId);
    scrollToHeaderElement(headerId);
  };

  const level = parseInt(tagName.substring(1)) - rootHeaderLevel;

  return (
    <Li level={level}>
      <Button
        level={level}
        isCurrentPage={isCurrentPositionList[idx]}
        onClick={() => handleOnClick(id)}
      >
        <span>{innerText}</span>
        <DiagonalBox />
      </Button>
    </Li>
  );
};
