import { Link } from "gatsby";
import styled from "styled-components";

export const TagLink = styled(Link)`
  height: 30px;

  padding: 4px 8px;
  border-radius: 16px;
  margin-left: 5px;
  margin-bottom: 5px;

  background-color: ${({ theme }) => theme.colors.gray};

  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlightColor};
  }
`;
