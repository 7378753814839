import styled from "styled-components";

export const TagWrapper = styled.div`
  padding: 30px 0 5px 0;
  display: flex;
  justify-content: flex-end;

  & > svg {
    margin-top: 2.5px;
    min-width: 25px;
    color: ${({ theme }) => theme.colors.textColor};
  }

  ${({ theme }) => theme.preventUserSelect};
`;
