import React, { useEffect, useState } from "react";
import { HeaderNavigatorButton } from "./button";
import {
  getCurrentPositionList,
  getHeaderPositionList,
  getMdxHeaderList,
  getRootHeaderLevel,
  useWindowPositionY,
} from "../../../utils";
import { NavSection } from "./section";
import { Nav } from "./wrapper";
import { Ul } from "./container";
import { DisplayAds } from "../../display-ad";

export const HeaderNavigator = () => {
  const [mdxHeaders, setMdxHeaders] = useState();
  const [rootHeaderLevel, setRootHeaderLevel] = useState(4);
  const [loadingMdx, setLoadingMdx] = useState(true);
  const [headerPositionList, setHeaderPositionList] = useState([]);
  const [isCurrentPositionList, setIsCurrentPositionList] = useState([]);

  const currentHeight = useWindowPositionY();

  useEffect(() => {
    const mdxList = getMdxHeaderList();
    setMdxHeaders(mdxList);
    setHeaderPositionList(getHeaderPositionList(mdxList));
    setIsCurrentPositionList(Array(mdxList.length).fill(false));

    const headerTags = mdxList.map((element) => element.tagName);
    setRootHeaderLevel(getRootHeaderLevel(headerTags));

    setLoadingMdx(false);
  }, []);

  useEffect(() => {
    if (loadingMdx) return;

    const newPositionList = getCurrentPositionList(
      headerPositionList,
      isCurrentPositionList.length,
      currentHeight
    );

    setIsCurrentPositionList(newPositionList);
  }, [
    loadingMdx,
    currentHeight,
    headerPositionList,
    isCurrentPositionList.length,
  ]);

  if (loadingMdx) {
    return <></>;
  }

  return (
    <NavSection>
      <Nav>
        <Ul>
          {mdxHeaders.map((element, idx) => (
            <HeaderNavigatorButton
              key={idx}
              element={element}
              rootHeaderLevel={rootHeaderLevel}
              idx={idx}
              isCurrentPositionList={isCurrentPositionList}
            />
          ))}
        </Ul>
        <DisplayAds dataAdSlot={"4542003988"} isFixedSize={true} style={{
          display:"inline-block", 
          width:"358px", 
          height:"300px",
        }} />
      </Nav>
    </NavSection>
  );
};
