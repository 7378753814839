import React from "react";
import { TO_TAG_BY_KOR } from "../../../../../constants";
import { getNamesByTagSlug } from "../../../../../utils";
import { TagLink } from "./link";
import { TagText } from "./text";

export const Tag = ({ tag }) => {
  const tagName = getNamesByTagSlug(tag).ko;
  return (
    <TagLink to={`/tag/${tag}`} title={TO_TAG_BY_KOR(tagName)}>
      <TagText>{tagName}</TagText>
    </TagLink>
  );
};
