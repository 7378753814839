import styled from "styled-components";

export const NavSection = styled.section`
  display: none;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    display: block;
  }

  ${({ theme }) => theme.preventUserSelect};
`;
